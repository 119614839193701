import React from "react";
import { SvgIcon } from "@material-ui/core";

const MetalicIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 88 88">
      <g id="Group_1177" data-name="Group 1177">
        <path
          id="Path_2457"
          data-name="Path 2457"
          d="M0,77.733A10.278,10.278,0,0,0,10.267,88H77.733A10.278,10.278,0,0,0,88,77.733V10.267A10.278,10.278,0,0,0,77.733,0H10.267A10.278,10.278,0,0,0,0,10.267Zm10.267-74.8H77.733a7.342,7.342,0,0,1,7.333,7.333V77.733a7.342,7.342,0,0,1-7.333,7.333H10.267a7.342,7.342,0,0,1-7.333-7.333V10.267A7.342,7.342,0,0,1,10.267,2.933Z"
        />
        <path
          id="Path_2458"
          data-name="Path 2458"
          d="M10.133,15.267A5.133,5.133,0,1,0,5,10.133,5.133,5.133,0,0,0,10.133,15.267Zm0-7.333a2.2,2.2,0,1,1-2.2,2.2A2.2,2.2,0,0,1,10.133,7.933Z"
          transform="translate(2.333 2.333)"
        />
        <path
          id="Path_2459"
          data-name="Path 2459"
          d="M53.133,15.267A5.133,5.133,0,1,0,48,10.133,5.133,5.133,0,0,0,53.133,15.267Zm0-7.333a2.2,2.2,0,1,1-2.2,2.2,2.2,2.2,0,0,1,2.2-2.2Z"
          transform="translate(22.4 2.333)"
        />
        <path
          id="Path_2460"
          data-name="Path 2460"
          d="M10.133,48a5.133,5.133,0,1,0,5.133,5.133A5.133,5.133,0,0,0,10.133,48Zm0,7.333a2.2,2.2,0,1,1,2.2-2.2A2.2,2.2,0,0,1,10.133,55.333Z"
          transform="translate(2.333 22.4)"
        />
        <path
          id="Path_2461"
          data-name="Path 2461"
          d="M53.133,48a5.133,5.133,0,1,0,5.133,5.133A5.133,5.133,0,0,0,53.133,48Zm0,7.333a2.2,2.2,0,1,1,2.2-2.2,2.2,2.2,0,0,1-2.2,2.2Z"
          transform="translate(22.4 22.4)"
        />
        <path
          id="Path_2462"
          data-name="Path 2462"
          d="M5.467,33.33A1.462,1.462,0,0,0,6.5,32.9L32.9,6.5A1.467,1.467,0,0,0,30.83,4.427l-26.4,26.4a1.467,1.467,0,0,0,1.037,2.5Z"
          transform="translate(1.867 1.87)"
        />
        <path
          id="Path_2463"
          data-name="Path 2463"
          d="M5.467,43.6a1.462,1.462,0,0,0,1.037-.43L43.17,6.5A1.467,1.467,0,0,0,41.1,4.427L4.43,41.093a1.467,1.467,0,0,0,1.037,2.5Z"
          transform="translate(1.867 1.87)"
        />
        <path
          id="Path_2464"
          data-name="Path 2464"
          d="M62.833,36.427l-26.4,26.4A1.467,1.467,0,1,0,38.507,64.9l26.4-26.4a1.467,1.467,0,0,0-2.074-2.074Z"
          transform="translate(16.797 16.803)"
        />
        <path
          id="Path_2465"
          data-name="Path 2465"
          d="M66.1,29.427,29.433,66.093a1.467,1.467,0,1,0,2.074,2.074L68.173,31.5A1.467,1.467,0,0,0,66.1,29.427Z"
          transform="translate(13.53 13.536)"
        />
      </g>
    </SvgIcon>
  );
};

export default MetalicIcon;
