import React from "react";

import { Card, CardContent, Avatar } from "@material-ui/core";
import Carousel from "../common/Carousel";
import { makeStyles } from "@material-ui/core/styles";

const Testimonial1 = () => {
  const testimonialList = [
    {
      companyLogoUrl: "./assets/images/Shout.png",
      user: {
        weblink: "https://youtu.be/Xe7841tZVwY",
        imageUrl: "./assets/images/face-1.jpg",
        words: "That was great - you sound like you've been doing this for years!",
        name: "Shout Radio - Toby Gribben Show",
        designation: "Toby Gribben",
      },
    },
    {
      companyLogoUrl: "./assets/images/IHope.png",
      user: {
        weblink: "https://podcasts.apple.com/us/podcast/inspiring-h-o-p-e-today/id1498218644",
        imageUrl: "./assets/images/face-2.jpg",
        words: "It was great to have you on the show. A book like this is so needed at a time like this.",
        name: "Inspiring Hope Podcast",
        designation: "Traci Williams",
      },
    },
  ];

  return (
    <div className="section" id="testimonial1">
      <div className="container">
        <div className="section__header">
          <h2>Speaking</h2>
          <p>
            Podcasts and Events I Have Been Featured On
          </p>
        </div>

        <Carousel carouselId="testimonial-1">
          {testimonialList.map((testimonial, index) => (
            <Card className="px-6 card w-full h-full" key={index}>
              <CardContent className="flex-column justify-between min-h-full">
                <div className="mb-6 mt-4">
                <a href={testimonial.user.weblink}><Avatar
                    className="w-48 h-48"
                    src={testimonial.companyLogoUrl}
                  /></a>
                </div>

                <p className="mt-0 mb-16 text-16">
                  {testimonial.user.words}
                </p>

                <div className="flex flex-wrap items-center">
                  <div className="pl-4">
                    <p className="m-0">
                      <strong>{testimonial.user.name}</strong>
                    </p>
                    <p className="m-0">{testimonial.user.designation}</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial1;
