import React from "react";
import { SvgIcon } from "@material-ui/core";

const StarterIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 49.057 27.211">
      <path
        id="Path_2514"
        data-name="Path 2514"
        d="M48.29,54H39.136a.767.767,0,0,0-.767.767v3.484H29.894a.767.767,0,0,0-.767.767v3.031H20.313a.767.767,0,0,0-.767.767V65.5H11.114a.767.767,0,0,0-.767.767V69.33H.767a.767.767,0,0,0,0,1.533H11.114a.767.767,0,0,0,.767-.767V67.031h8.432a.767.767,0,0,0,.767-.767V63.581h8.815a.767.767,0,0,0,.767-.767V59.784h8.476a.767.767,0,0,0,.767-.767V55.533H48.29a.767.767,0,1,0,0-1.533Z"
        transform="translate(0 -43.652)"
      />
      <path
        id="Path_2515"
        data-name="Path 2515"
        d="M9.442,40.791a2.3,2.3,0,0,0,1.265.13q.021.266.053.519l0,.026,0,.026A5.382,5.382,0,0,1,10.826,43l-.017.065-.012.066c-.195,1.091-.735,4.071-.9,4.756a2.711,2.711,0,0,0,.552,2.449,2.39,2.39,0,0,0,1.8.807,2.489,2.489,0,0,0,2.414-1.929l.723-3.064a2.233,2.233,0,0,0,.826.73,2.49,2.49,0,0,0,1.155.281,2.439,2.439,0,0,0,1.81-.782,2.663,2.663,0,0,0,.684-1.725l.153-2.79.008-.109a2.434,2.434,0,0,0-1.2-2.522l-.088-.051A2.226,2.226,0,0,0,20.266,37a2.36,2.36,0,0,0-.562-1.546,2.288,2.288,0,0,0-1.758-.78l-.669-.025c-.03-.192-.069-.39-.12-.6A3.5,3.5,0,0,0,16.091,32.2,2.938,2.938,0,0,0,17.2,29.874,2.787,2.787,0,0,0,14.517,27a2.787,2.787,0,0,0-2.683,2.874,2.99,2.99,0,0,0,.573,1.77,3.325,3.325,0,0,0-.67.589l-2.07,2.25a2.9,2.9,0,0,0-.586.938L8.159,37.8A2.326,2.326,0,0,0,9.442,40.791Zm5.075-12.258a1.357,1.357,0,0,1,0,2.683,1.357,1.357,0,0,1,0-2.683ZM9.588,38.357l.921-2.38a1.387,1.387,0,0,1,.289-.459l2.071-2.25a1.1,1.1,0,0,1,1.153-.5,2.7,2.7,0,0,1,.956.478,1.963,1.963,0,0,1,.687,1.156,6.667,6.667,0,0,1,.176,1.493c0,.12.007.23.125.237l1.951.071h.022c1.082,0,.964,1.531.207,1.531-.007,0-1.538-.012-2.091-.012h-.014c-.193,0-.189.155-.189.155,0,.043,0,.083-.005.127-.006.18-.015.36-.018.541-.007.387-.083.736.329.91.007,0,1.911,1.111,1.911,1.111.533.3.443.716.412,1.212L18.327,44.6a1.126,1.126,0,0,1-.288.752.909.909,0,0,1-.674.279.953.953,0,0,1-.441-.1c-.451-.237-.467-.792-.475-1.248,0-.016.019-1.954.019-1.954a1.008,1.008,0,0,0-.143-.624L14.6,40.961a.208.208,0,0,0-.1-.035.07.07,0,0,0-.043.013.135.135,0,0,0-.036.144l.112,1.075a3.428,3.428,0,0,1-.021,1.063l-.127.467-1.225,5.192a.951.951,0,0,1-.918.731.87.87,0,0,1-.656-.3,1.22,1.22,0,0,1-.2-1.071c.207-.876.914-4.838.914-4.838a6.856,6.856,0,0,0-.025-2.158,23.835,23.835,0,0,1,.178-5.149l.03-.158-.567.631-.914,2.362a.763.763,0,0,1-.709.493.754.754,0,0,1-.281-.054A.786.786,0,0,1,9.588,38.357Z"
        transform="translate(-4.936 -27)"
      />
    </SvgIcon>
  );
};

export default StarterIcon;
