import React from "react";
import { SvgIcon } from "@material-ui/core";

const TwitterIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 26.042 21.303">
      <g
        id="Component_99_66"
        data-name="Component 99 – 66"
        transform="translate(0 0)"
      >
        <path
          id="Path_47"
          data-name="Path 47"
          d="M25.682,2.477a10.5,10.5,0,0,1-3.031.815,5.111,5.111,0,0,0,2.314-2.9,10.332,10.332,0,0,1-3.341,1.271,5.276,5.276,0,0,0-7.57-.122,5.073,5.073,0,0,0-1.54,3.723,5.842,5.842,0,0,0,.13,1.206A14.667,14.667,0,0,1,6.591,4.848,14.922,14.922,0,0,1,1.792.961a5.156,5.156,0,0,0-.717,2.656,5.127,5.127,0,0,0,.636,2.51A5.258,5.258,0,0,0,3.422,8a5.222,5.222,0,0,1-2.379-.668V7.4a5.086,5.086,0,0,0,1.2,3.349,5.178,5.178,0,0,0,3.023,1.817,5.453,5.453,0,0,1-1.385.179,6.715,6.715,0,0,1-.994-.081,5.275,5.275,0,0,0,4.921,3.65A10.306,10.306,0,0,1,1.271,18.56,11.205,11.205,0,0,1,0,18.5a14.618,14.618,0,0,0,8.083,2.363,15.378,15.378,0,0,0,5.263-.888,13.377,13.377,0,0,0,4.2-2.379,15.921,15.921,0,0,0,3.006-3.43,15.3,15.3,0,0,0,1.882-4.049,14.959,14.959,0,0,0,.619-4.229q0-.456-.016-.684A11.023,11.023,0,0,0,25.682,2.477Z"
          transform="matrix(1, -0.017, 0.017, 1, 0, 0.448)"
        />
      </g>
    </SvgIcon>
  );
};

export default TwitterIcon;
