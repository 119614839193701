import React from "react";
import { SvgIcon } from "@material-ui/core";

const EuroIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <path
        id="Path_2511"
        data-name="Path 2511"
        d="M23.311,2.5A20.811,20.811,0,1,0,44.121,23.311,20.829,20.829,0,0,0,23.311,2.5ZM6.268,23.311a16.865,16.865,0,0,1,3.724-10.6l4.556,4.556A10.9,10.9,0,0,1,16.87,14.2l-4.162-4.162A17.02,17.02,0,0,1,36.629,33.957L32.642,29.97a2.133,2.133,0,0,1,.044.526,2.583,2.583,0,0,1-.745,1.84,9.172,9.172,0,0,1-1.227,1.051l3.242,3.242A17.052,17.052,0,0,1,6.268,23.311Z"
        transform="translate(-2.5 -2.5)"
      />
      <path
        id="Path_2512"
        data-name="Path 2512"
        d="M37.872,30.236a5.029,5.029,0,0,1,3.549,1.533.5.5,0,0,0,.745,0l1.84-1.709a.531.531,0,0,0,0-.789A8.75,8.75,0,0,0,37.828,26.6a9.3,9.3,0,0,0-8.543,6.528H27.27a.58.58,0,0,0-.57.57v1.665a.58.58,0,0,0,.57.57h1.4a6.171,6.171,0,0,0-.044.876,5.872,5.872,0,0,0,.044.876h-1.4a.58.58,0,0,0-.57.57v1.665a.58.58,0,0,0,.57.57h2.015a9.238,9.238,0,0,0,8.543,6.528,8.75,8.75,0,0,0,6.177-2.673.531.531,0,0,0,0-.789l-1.84-1.665a.506.506,0,0,0-.745.044,5.137,5.137,0,0,1-3.549,1.533,5.387,5.387,0,0,1-4.6-2.935h4.775a.58.58,0,0,0,.57-.57V38.3a.58.58,0,0,0-.57-.57H32.4a8.784,8.784,0,0,1,0-1.752h5.652a.58.58,0,0,0,.57-.57V33.741a.58.58,0,0,0-.57-.57H33.316A5.41,5.41,0,0,1,37.872,30.236Z"
        transform="translate(-16.098 -16.041)"
      />
    </SvgIcon>
  );
};

export default EuroIcon;
