import React from "react";
import { SvgIcon } from "@material-ui/core";

const StrategyIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 81.05 96.476">
      <path
        id="Path_2165"
        data-name="Path 2165"
        d="M61.5,63.363a17.087,17.087,0,0,1,5.076,14.762l-.3,2.28a1.723,1.723,0,0,0,1.54,2.028V94.072a6.733,6.733,0,0,0,6.792,6.738H87.97a6.776,6.776,0,0,0,5.214-2.381l-1.822-1.56a4.414,4.414,0,0,1-3.392,1.555H74.61A4.345,4.345,0,0,1,70.2,94.073V91.266H86.31V88.88H70.2V82.517H84.52V80.131H68.729l.21-1.649a19.511,19.511,0,0,0-5.8-16.835,26.514,26.514,0,1,1,43.93-13.113l2.32.558A28.9,28.9,0,1,0,61.5,63.363Z"
        transform="translate(-52.385 -13.401)"
      />
      <path
        id="Path_2166"
        data-name="Path 2166"
        d="M257.439,197.019,233.554,185.3a1.193,1.193,0,0,0-1.05,0l-23.861,11.686a1.171,1.171,0,0,0-.645,1.071v22.358a18.912,18.912,0,0,0,1.9,7.83,20.491,20.491,0,0,0,6.551,8.2l15.917,10.849a1.186,1.186,0,0,0,1.339,0l15.922-10.848c4.757-3.241,8.482-10.28,8.482-16.028V198.09a1.193,1.193,0,0,0-.668-1.071Zm-1.719,23.4c0,4.956-3.338,11.261-7.44,14.056l-15.251,10.391L217.778,234.47a18.037,18.037,0,0,1-5.719-7.231,16.783,16.783,0,0,1-1.674-6.825V198.8l22.668-11.1,22.668,11.135Z"
        transform="translate(-177.057 -151.022)"
      />
      <path
        id="Path_2167"
        data-name="Path 2167"
        d="M279.08,309.99a1.193,1.193,0,1,0-1.666,1.708l7.214,7.037a1.193,1.193,0,0,0,1.666,0l13-12.677a1.193,1.193,0,0,0-1.666-1.708l-12.165,11.865Z"
        transform="translate(-232.381 -246.226)"
      />
    </SvgIcon>
  );
};

export default StrategyIcon;
