import React from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  sectionBG: ({ bg }) => ({
    background: `url(${
      bg || "./assets/images/home-bg.jpg"
    }) center center/cover no-repeat`,
  }),
}));

const CallToAction1 = ({ bg }) => {
  const classes = useStyles({ bg });

  return (
    <div className={clsx("section text-white", classes.sectionBG)} id="cta1">
      <div className="container">
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <h2>Dont miss this chance to book a top flight podcast guest</h2>
            <p className="text-inherit">
              Questions to ask Ronal
              <p></p>
              <li> How do you combine trading with spirituality?              </li>
              <li> Why did you write The Trader's Path to Inner Wisdom?              </li>
              <br/>

              <li>What is the link between spirituality and business?</li>
            </p>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
            <Button size="large" color="secondary" variant="contained">
              ronal.shah@outlook.com
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CallToAction1;
