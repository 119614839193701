import React from "react";
import Footer2 from "./sections/Footer2";

import TopBar10 from "./sections/TopBar10";
import Intro10 from "./sections/Intro10";
import Contact2 from "./sections/Contact2";
import Booking1 from "./sections/Booking1";
import Gallery2 from "./sections/Gallery2";
import HotelTour from "./sections/HotelTour";
import Services10 from "./sections/Services10";
import Blog1 from "./sections/Blog1";
import Testimonial8 from "./sections/Testimonial8";
import Conference1 from "./sections/Conference1";
import EventSchedule1 from "./sections/EventSchedule1";
import Speakers1 from "./sections/Speakers1";
import EventDetails1 from "./sections/EventDetails1";
import Pricing3 from "./sections/Pricing3";
import Testimonial9 from "./sections/Testimonial9";
import Subscription4 from "./sections/Subscription4";

const Landing10 = () => {
  return (
    <div className="landing">
      <TopBar10 />
      <Intro10 />
      <Conference1 />
      <Speakers1 />
      <EventSchedule1 />
      <EventDetails1 />
      <Pricing3 />
      <Testimonial9 />
      <Blog1 />
      <Subscription4 />
      <Footer2 />
    </div>
  );
};

export default Landing10;
