import React from "react";
import { SvgIcon } from "@material-ui/core";

const PenIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 96.222 96.222">
      <path
        id="Path_2144"
        data-name="Path 2144"
        d="M102.837,210.976a1.369,1.369,0,0,0,.962-.858l12.806-34.759a1.369,1.369,0,1,0-2.569-.947l-12.554,34.073L51.405,220.614l24.739-24.738a10.428,10.428,0,1,0-1.935-1.937L49.469,218.678,61.6,168.6,95.67,156.048a1.369,1.369,0,1,0-.947-2.569L59.965,166.284a1.369,1.369,0,0,0-.857.963L45.752,222.4l-.186.186a1.369,1.369,0,1,0,1.936,1.936l.185-.185Zm-25.79-28.81a7.686,7.686,0,1,1,0,10.869,7.686,7.686,0,0,1,0-10.869Z"
        transform="translate(-45.165 -128.696)"
      />
      <path
        id="Path_2145"
        data-name="Path 2145"
        d="M257.152,101.47a1.369,1.369,0,0,0,1.936,0l15.55-15.55a1.369,1.369,0,0,0,0-1.936L236.221,45.566a1.369,1.369,0,0,0-1.936,0l-15.55,15.55a1.369,1.369,0,0,0,0,1.936Zm-21.9-53,36.482,36.482L258.12,98.566,221.639,62.084Z"
        transform="translate(-178.818 -45.165)"
      />
    </SvgIcon>
  );
};

export default PenIcon;
